import React from "react"
import Image from "next/image"
import Link from "next/link"

import tiktok from "/public/icons/socials/tiktok.png"
import facebook from "/public/icons/socials/facebook.png"
import instagram from "/public/icons/socials/instagram.png"
import linkedin from "/public/icons/socials/linkedin.png"
import twitter from "/public/icons/socials/twitter.png"
import pinterest from "/public/icons/socials/pinterest.png"
import youtube from "/public/icons/socials/youtube.png"

type props = {}

export const Footer: React.FC<props> = (props) => {
  return (
    <section>
      <div className='wrapper footer'>
        {/* Links */}
        <div className='links'>
          <h4>Quick links</h4>

          <div className='h-stack'>
            <div className='content'>
              <Link rel='noreferrer' href='/return-refund-policy'>
                Return Policy
              </Link>
              <Link rel='noreferrer' href='/terms'>
                Terms of Use
              </Link>
              <Link rel='noreferrer' href='/privacy'>
                Privacy Policy
              </Link>
              <Link rel='noreferrer' href='/do-not-sell'>
                Do Not Sell
              </Link>
            </div>
          </div>
        </div>

        {/* Socials */}
        <div className='socials'>
          <a
            rel='noreferrer nofollow'
            href={"https://www.tiktok.com/@shopatruby"}
            target={"_blank"}
          >
            <Image src={tiktok} alt='tiktok' />
          </a>

          <a
            rel='noreferrer nofollow'
            href={"https://www.instagram.com/shoprubyapp/"}
            target={"_blank"}
          >
            <Image src={instagram} alt='instagram' />
          </a>

          <a
            rel='noreferrer nofollow'
            href={"https://www.pinterest.co.uk/shoprubyapp/_created/"}
            target={"_blank"}
          >
            <Image src={pinterest} alt='pinterest' />
          </a>

          <a
            rel='noreferrer nofollow'
            href={"https://www.facebook.com/shoprubyapp"}
            target={"_blank"}
          >
            <Image src={facebook} alt='facebook' />
          </a>

          <a
            rel='noreferrer nofollow'
            href={"https://www.youtube.com/channel/UCvzFeHaGAxx8yzK0ThtYDKQ"}
            target={"_blank"}
          >
            <Image src={youtube} alt='youtube' />
          </a>

          <a
            rel='noreferrer nofollow'
            href={"https://twitter.com/shopatruby"}
            target={"_blank"}
          >
            <Image src={twitter} alt='twitter' />
          </a>

          <a
            rel='noreferrer nofollow'
            href={"https://www.linkedin.com/company/69765029"}
            target={"_blank"}
          >
            <Image src={linkedin} alt='linkedin' />
          </a>
        </div>

        <div className='credits'>
          <p className='caption gray-text'>
            Ruby is the smart money-saving app that helps you Find deals at your favorite
            online stores & Save money with Coupons, Promo codes, Cash back and Ruby
            points.
          </p>
          <p className='footnote gray-text'>
            <Image src='/ruby-logo.png' alt='Ruby Logo' width={14} height={14 } />
            Copyright Ruby 2024, All rights reserved.
          </p>
        </div>
      </div>
    </section>
  )
}
