import React from "react"
import Link from "next/link"
import Image from "next/image"

import SvgApple from "/public/icons/apple.svg"
import ImgDownloadCta from "/public/images/download-cta-des.png"
import ImgDownloadCtaMob from "/public/images/download-cta-mob.png"

type props = {}

export const DownloadCTA: React.FC<props> = (props) => {
  return (
    <div className='download-cta'>
      <div className='details'>
        <h3>
          Download Ruby
          <br />
          for iPhone
        </h3>

        <Link
          href={"https://apps.apple.com/us/app/ruby-shop-with-coupons-codes/id1543508948"}
        >
          <button className='btn btn-lg-mix bg-primary secondary round'>
            <SvgApple />
            App Store
          </button>
        </Link>
      </div>

      <Image className='des' src={ImgDownloadCta} layout='fill' objectFit='cover' />
      <Image className='mob' src={ImgDownloadCtaMob} layout='fill' objectFit='cover' />
    </div>
  )
}
